<div class="chatbotPopup" *ngIf="show">
  <div class="card">
    <div class="card-header">
      <i nz-icon nzType="robot" nzTheme="outline"></i>
      <span>Chatbot</span>
      <button class="btn btn-sm btn-outline-secondary close-button" (click)="toggleChat()">
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </button>
    </div>
    <div class="card-body" #chatBody>
      <ng-container *ngFor="let message of messages; let i = index">
        <div class="message" [ngClass]="{ from: message.author === 'bot', to: message.author === 'user' }">
          <div class="icon-container" *ngIf="message.author === 'bot'">
            <i nz-icon nzType="robot" nzTheme="outline"></i>
          </div>
          <div class="message-content">{{ message.content }}</div>
          <div class="icon-container" *ngIf="message.author === 'user'">
            <i nz-icon nzType="user" nzTheme="outline"></i>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="card-footer">
      <input type="text" class="form-control" placeholder="Type your message..." [(ngModel)]="val"
        (keyup.enter)="sendMessage(val)" #messageInput>
      <button class="btn btn-primary" (click)="sendMessage(val)">
        <i nz-icon nzType="send" nzTheme="outline"></i>
      </button>
    </div>
  </div>
</div>

<div class="chatIcon" (click)="toggleChat()">
  <i nz-icon nzType="message" nzTheme="fill" class="message-icon"></i>
</div>