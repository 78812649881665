<div id="page">
	<div class="gtco-loader"></div>
	<nav class="gtco-nav sticky-header" role="navigation">
		<nz-affix>
			<div class="container">
				<div class="row">
					<div class="col-sm-2 col-xs-12">
						<div id="gtco-logo"><a href="index.html">JLM<em>Consulting</em></a></div>
					</div>
					<div class="col-xs-10 text-right menu-1 main-nav">
						<ul>
							<li>
								<a (click)="scroll(0)" data-nav-section="home">Home</a>
							</li>
							<li><a (click)="scroll(1500)" data-nav-section="about">Services</a></li>
							<li><a (click)="scroll(2800)" data-nav-section="practice-areas">About Us</a></li>
							<!-- <li><a (click)="scroll(600)" data-nav-section="our-team"></a></li> -->
							<li class="btn-cta"><a (click)="scroll(3150)"
									data-nav-section="contact"><span>Contact</span></a></li>
						</ul>
					</div>
				</div>
			</div>
		</nz-affix>
	</nav>
	<section class="gtco-cover" style="background-image: url(assets/images/professionals_office.png);"
		data-section="home" data-stellar-background-ratio="0.5">
		<div class="overlay"></div>
		<div class="container">
			<div class="row">
				<div class="col-md-12 col-md-offset-0 text-center">
					<div class="display-t">
						<div class="display-tc">
							<h1 class="animate-box" data-animate-effect="fadeIn">Experts in Multi-Cloud Services and
								Solutions Architecture</h1>
							<p class="gtco-video-link animate-box" data-animate-effect="fadeIn">
								<a class="gtco-video-link animate-box" (click)="openVideoPopup()" class="popup-vimeo">
									<i nz-icon nzType="play-circle" style="color: white" nzTheme="outline"
										style="font-size: 42px;"></i>
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>



	<section #section1 id="gtco-about" data-section="about">
		<div class="container">
			<div class="row row-pb-md">
				<div class="col-md-8 col-md-offset-2 heading animate-box" data-animate-effect="fadeIn">
					<h1>Welcome To JLM Consulting's Multi-Cloud Solutions</h1>
					<p class="sub">Discover the power of multi-cloud service and solutions architecture.</p>
					<!-- <p class="subtle-text animate-box" data-animate-effect="fadeIn">JLM</p> -->
				</div>
			</div>
			<div class="row">
				<div class="col-md-6 col-md-pull-1 animate-box" data-animate-effect="fadeInLeft">
					<div class="img-shadow">
						<img src="assets/images/niceguy2.png" class="img-responsive"
							alt="Multi-Cloud Solutions by JLM Consulting">
					</div>
				</div>
				<div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
					<h2 class="heading-colored">Excellence in Cloud Architecture</h2>
					<p>At JLM Consulting's Multi-Cloud Solutions, we are committed to delivering top-notch cloud
						architecture services to businesses of all sizes. Our team of experts specializes in
						designing and implementing multi-cloud solutions tailored to meet your specific needs and
						objectives.</p>
					<p>With our extensive experience in cloud technologies and a deep understanding of various cloud
						platforms, we ensure seamless integration, scalability, and security across multiple clouds.
						Whether you are looking to migrate to the cloud, optimize your existing cloud
						infrastructure, or implement a multi-cloud strategy, we have the expertise to make it
						happen.</p>
					<p> <a class="read-more" (click)="openModal(1)">Read more <i nz-icon nzType="right"></i></a>
					</p>
				</div>
			</div>
		</div>
	</section>

	<section id="gtco-services" data-section="services">
		<div class="container">
			<div class="row row-pb-md">
				<div class="col-md-8 col-md-offset-2 heading animate-box" data-animate-effect="fadeIn">
					<h1>Our Services</h1>
					<p class="sub">We offer a wide range of consulting services to help your business succeed.</p>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4 col-sm-4 animate-box" data-animate-effect="fadeIn">
					<div class="gtco-services">
						<h3><i nz-icon nzType="cloud" nzTheme="outline" style="font-size: 24px; color: #6e52d4be;"></i>
							Cloud Migration</h3>
						<p>We specialize in seamless migration of your infrastructure to the cloud, ensuring minimal
							disruption to your business operations. Our team of experts will assess your needs and
							design a tailored migration plan to optimize performance, scalability, and
							cost-efficiency.</p>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 animate-box" data-animate-effect="fadeIn">
					<div class="gtco-services">
						<h3><i nz-icon nzType="deployment-unit" nzTheme="outline"
								style="font-size: 24px; color: #6e52d4be;"></i> Cloud Optimization</h3>
						<p>Maximize the potential of your cloud environment with our optimization services. Our team
							will analyze your existing infrastructure, identify performance bottlenecks, and
							implement strategies to enhance efficiency and reduce costs. We ensure your applications
							and systems perform at their best.</p>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 animate-box" data-animate-effect="fadeIn">
					<div class="gtco-services">
						<h3><i nz-icon nzType="lock" nzTheme="outline" style="font-size: 24px; color: #6e52d4be;"></i>
							Cloud Security</h3>
						<p>Your data security is our top priority. We offer comprehensive cloud security solutions
							to protect your valuable assets from threats and vulnerabilities. Our team will
							implement robust security measures, conduct audits, and provide ongoing monitoring to
							safeguard your business against cyber threats.</p>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4 col-sm-4 animate-box" data-animate-effect="fadeIn">
					<div class="gtco-services">
						<h3><i nz-icon nzType="bar-chart" nzTheme="outline"
								style="font-size: 24px; color: #6e52d4be;"></i> Data Analytics</h3>
						<p>Unlock the power of your data with our data analytics services. We help you extract
							meaningful insights from your data, enabling you to make data-driven decisions and gain
							a competitive edge. Our team employs advanced analytics techniques to uncover trends,
							patterns, and opportunities for growth.</p>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 animate-box" data-animate-effect="fadeIn">
					<div class="gtco-services">
						<h3><i nz-icon nzType="mobile" nzTheme="outline" style="font-size: 24px; color: #6e52d4be;"></i>
							Mobile App Development</h3>
						<p>Tap into the rapidly growing mobile market with our custom mobile app development
							services. Our experienced developers will bring your app idea to life, creating
							intuitive and engaging mobile experiences. We follow industry best practices to ensure
							your app is user-friendly, scalable, and compatible across platforms.</p>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 animate-box" data-animate-effect="fadeIn">
					<div class="gtco-services">
						<h3><i nz-icon nzType="tool" nzTheme="outline" style="font-size: 24px; color: #6e52d4be;"></i>
							IT Consulting</h3>
						<p>Get strategic guidance and expert advice on all your IT-related challenges. Our IT
							consulting services cover a wide range of areas, including technology planning,
							infrastructure management, software selection, and IT project management. We partner
							with you to align technology with your business goals and drive success.</p>
					</div>
				</div>
			</div>
		</div>

	</section>
	<br />
	<br />
	<section id="security" class="row team-item gtco-team-reverse">
		<div class="container">
			<div class="row">
				<div class="col-md-6 col-md-push-7 animate-box" data-animate-effect="fadeInLeft">
					<div class="img-shadow">
						<img src="assets/images/women_secure.png" alt="Security" class="img-responsive img-float-right">
					</div>
				</div>
				<div class="col-md-6 col-md-pull-6">
					<div class="gtco-heading">
						<h2 class="heading-colored">Robust Security Measures</h2>
					</div>
					<div class="gtco-paragraph">
						<p>At JLM Consulting, we prioritize the security of your data and applications. With our
							expertise in cloud architecture, we implement robust security measures to safeguard your
							valuable assets.</p>
						<p>Our team of security experts utilizes industry best practices and cutting-edge
							technologies to ensure that your systems are protected against threats and
							vulnerabilities. We understand the importance of confidentiality, integrity, and
							availability of your data, and we go the extra mile to provide comprehensive security
							solutions.</p>
						<p> <a class="read-more" (click)="openModal(2)">Read more <i nz-icon nzType="right"></i></a></p>
					</div>
				</div>
			</div>
		</div>
	</section>

	<br />
	<br />
	<section id="gtco-testimonials" data-section="testimonials">
		<div class="container">
			<div class="row row-pb-md">
				<div class="col-md-8 col-md-offset-2 heading animate-box" data-animate-effect="fadeIn">
					<h1>Client Testimonials</h1>
					<p class="sub">Hear what our clients have to say about our services.</p>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6 animate-box" data-animate-effect="fadeIn">
					<div class="gtco-testimonials">
						<blockquote>
							<p>"JLM Consulting has been instrumental in helping us migrate our entire infrastructure
								to the cloud. Their expertise and dedication ensured a smooth and efficient
								transition. We are now benefiting from improved scalability, flexibility, and cost
								savings."</p>
							<p class="testimonial-author">- Peter Mills, CEO of Open Cloud Limited.</p>
						</blockquote>
					</div>
				</div>
				<div class="col-md-6 animate-box" data-animate-effect="fadeIn">
					<div class="gtco-testimonials">
						<blockquote>
							<p>"We engaged JLM Consulting to optimize our cloud environment, and the results have
								been outstanding. Their team identified and resolved performance bottlenecks,
								resulting in significant cost savings and improved application performance. Their
								expertise and support have been invaluable."</p>
							<p class="testimonial-author">- Lovell Nicanor, CEO of E-Quainted Inc.</p>
						</blockquote>
					</div>
				</div>
			</div>
		</div>
	</section>
	<br />
	<br />
	<app-contact-form id="contact"></app-contact-form>
	<div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="13" data-height="50" data-nofollow="true" data-expandifr="true" data-scale="100" data-clutchcompany-id="2273736"></div>
	<div class="gototop js-top">
		<nz-back-top>Test</nz-back-top>
	</div>
	<app-chat-box></app-chat-box>
	<footer id="gtco-footer" role="contentinfo">
		<div class="container">
			<div class="row">
				<div class="col-md-12 text-center">
					<p>
						<small class="block">©2013 JLM IT Consulting Services. All rights reserved.</small>
						<small class="block">Designed by <a href="http://jasonmullings.com" target="_blank">Jason Mullings</a></small>
					</p>
					<ul class="gtco-social-icons">
						<li><a (click)="openPopup('https://twitter.com/JLMITConsulting')"><i class="icon-twitter"></i></a></li>
						<li><a (click)="openPopup('https://twitter.com/JLMITConsulting')"><i class="icon-facebook"></i></a></li>
						<li><a (click)="openPopup('https://www.linkedin.com/company/e-quainted')"><i class="icon-linkedin"></i></a></li>
						<li><a (click)="openPopup('https://github.com/e-quainted')"><i class="icon-dribbble"></i></a></li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
</div>