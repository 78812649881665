import { Message, ChatService } from "src/app/services/chat-service.service";

import {
    ViewChild, Renderer2, OnInit, ElementRef, Component, AfterViewChecked
} from "@angular/core";

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss']
})
export class ChatBoxComponent implements OnInit, AfterViewChecked {
  @ViewChild('chatBody') private chatBody!: ElementRef;
  @ViewChild('messageInput') messageInput!: ElementRef;

  show = false;
  messages: Message[] = [];
  value!: string;
  val: any;

  constructor(
    public chatService: ChatService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.chatService.conversation.subscribe((val) => {
      this.messages = this.messages.concat(val);
    });
    this.sendMessage("Hello JLM Consulting!")
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  toggleChat() {
    this.show = !this.show;
    if (this.show) {
      setTimeout(() => {
        this.messageInput.nativeElement.focus();
      }, 0); // Focus on input when chat is opened
    }
  }

  sendMessage(val: string) {
    if (val && val.trim() !== '') { // Prevent sending empty messages
      this.chatService.getBotAnswer(val);
      this.value = '';
      this.val = '';
    }
  }

  scrollToBottom() {
    try {
      this.chatBody.nativeElement.scrollTop = this.chatBody.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }
}